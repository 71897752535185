import { takeLatest, put } from 'redux-saga/effects'
import { ac } from '../actions'
import { notification } from 'antd'
import {
  CREATE_PAYMENT_TOKEN,
  CREATE_PAYMENT_TOKEN_FAILED,
  CREATE_PAYMENT_TOKEN_SUCCESS,
  PROCESS_PRESCRIPTION_PAYMENT,
  PROCESS_PRESCRIPTION_PAYMENT_FAILED,
  PROCESS_PRESCRIPTION_PAYMENT_SUCCESS,
  processPrescriptionPayment,
} from './actions'
import RequestHelper from '../../helpers/RequestHelper'
import {
  ICreatePaymentTokenResponse,
  ICreateSecure3dIframeResponse,
  IResponse,
} from '../../types'
import NavigationHelper from '../../helpers/NavigationHelper'
import CommBankSimplifyHelper from '../../helpers/CommBankSimplifyHelper'

export function* performProcessPrescriptionPayment(action: any) {
  const {
    payload: { projectId, prescriptionFormFields, token, onComplete },
  } = action
  try {
    const resp: IResponse = yield RequestHelper.request('/payments', {
      method: 'POST',
      params: {
        projectId,
      },
      data: { formFields: prescriptionFormFields, token },
    })
    console.log('process prescription response', resp)
    onComplete()
    yield put(ac(PROCESS_PRESCRIPTION_PAYMENT_SUCCESS))
    NavigationHelper.navigate('/thankYou')
  } catch (ex) {
    console.log('process prescription payment failed', ex)
    yield put(ac(PROCESS_PRESCRIPTION_PAYMENT_FAILED))
    notification.error({
      message: ex?.response?.data?.message || 'Failed to process payment',
    })
    onComplete()
  }
}

export function* performCreatePaymentToken(action: any) {
  try {
    const {
      payload: {
        projectId,
        prescriptionFormFields,
        onComplete,
        onTokenReceived,
      },
    } = action
    const resp: ICreatePaymentTokenResponse = yield RequestHelper.request(
      '/payments/token',
      {
        method: 'POST',
        params: {
          projectId,
        },
        data: prescriptionFormFields,
      },
    )
    console.log('create payment token response', resp)

    yield put(ac(CREATE_PAYMENT_TOKEN_SUCCESS))

    const isEnrolled: boolean = resp.data.card.secure3DData.isEnrolled
    if (isEnrolled) {
      onTokenReceived()
      const { id, currency, amount, description, card } = resp.data
      console.log('isEnrolled', isEnrolled, resp)
      const completePayload: ICreateSecure3dIframeResponse =
        yield CommBankSimplifyHelper.createSecure3dIframe(
          id,
          currency,
          amount,
          description,
          card,
        )

      onComplete({
        projectId,
        prescriptionFormFields,
        token: completePayload.token,
      })
    } else {
      onComplete({
        projectId,
        prescriptionFormFields,
      })
    }
  } catch (ex) {
    console.log('create payment token failed', ex)
    yield put(ac(CREATE_PAYMENT_TOKEN_FAILED))
    notification.error({
      message: ex?.response?.data?.message || 'Failed to create payment token',
    })
  }
}

/* Watchers */
function* watchProcessPrescriptionPayment() {
  yield takeLatest(
    PROCESS_PRESCRIPTION_PAYMENT,
    performProcessPrescriptionPayment,
  )
}

function* watchCreatePaymentToken() {
  yield takeLatest(CREATE_PAYMENT_TOKEN, performCreatePaymentToken)
}

export default [watchProcessPrescriptionPayment(), watchCreatePaymentToken()]
