import { ac } from '../actions'
import { IPrescriptionFormFields, ProjectId } from '../../types'

export const PROCESS_PRESCRIPTION_PAYMENT = 'PROCESS_PRESCRIPTION_PAYMENT'
export const PROCESS_PRESCRIPTION_PAYMENT_SUCCESS =
  'PROCESS_PRESCRIPTION_PAYMENT_SUCCESS'
export const PROCESS_PRESCRIPTION_PAYMENT_FAILED =
  'PROCESS_PRESCRIPTION_PAYMENT_FAILED'
export const CREATE_PAYMENT_TOKEN = 'CREATE_PAYMENT_TOKEN'
export const CREATE_PAYMENT_TOKEN_SUCCESS = 'CREATE_PAYMENT_TOKEN_SUCCESS'
export const CREATE_PAYMENT_TOKEN_FAILED = 'CREATE_PAYMENT_TOKEN_FAILED'

export const processPrescriptionPayment = (
  projectId: ProjectId,
  prescriptionFormFields: IPrescriptionFormFields,
  token?: string,
  onComplete?: () => void,
) =>
  ac(PROCESS_PRESCRIPTION_PAYMENT, {
    projectId,
    prescriptionFormFields,
    token,
    onComplete,
  })

export const createPaymentToken = (
  projectId: ProjectId,
  prescriptionFormFields: IPrescriptionFormFields,
  {
    onTokenReceived,
    onComplete,
  }: {
    onTokenReceived: () => void
    onComplete: ({
      projectId,
      prescriptionFormFields,
      token,
    }: {
      projectId: ProjectId
      prescriptionFormFields: IPrescriptionFormFields
      token?: string
    }) => void
  },
) =>
  ac(CREATE_PAYMENT_TOKEN, {
    projectId,
    prescriptionFormFields,
    onComplete,
    onTokenReceived,
  })
