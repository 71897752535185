import StorageHelper from './StorageHelper'

const STORE_KEY = 'redux_state'

class StateHelper {
  public static persistState(state: any) {
    try {
      StorageHelper.setItem(STORE_KEY, state)
    } catch (err) {
      console.log('Failed to persist state', err)
    }
  }

  public static hydrateState() {
    try {
      const item = StorageHelper.getItem(STORE_KEY)
      return item || {}
    } catch (err) {
      console.log('Failed to hydrate state', StorageHelper.getItem(STORE_KEY))
      return undefined
    }
  }
}

export default StateHelper
