import $ from 'jquery/dist/jquery.slim'
import { ICreatePaymentCard, ICreateSecure3dIframeResponse } from '../types'

const CommBankSimplifyHelper = {
  createInput: (name: string, value: string) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', name)
    input.setAttribute('value', value)
    return input
  },

  createSecure3dForm: (cardData: ICreatePaymentCard) => {
    const secure3dData = cardData.secure3DData
    const secure3dForm = document.createElement('form')
    secure3dForm.setAttribute('method', 'POST')
    secure3dForm.setAttribute('action', secure3dData.acsUrl)
    secure3dForm.setAttribute('target', 'secure3d-frame')

    const merchantDetails = secure3dData.md
    const paReq = secure3dData.paReq
    const termUrl = secure3dData.termUrl

    secure3dForm.append(CommBankSimplifyHelper.createInput('PaReq', paReq))
    secure3dForm.append(CommBankSimplifyHelper.createInput('TermUrl', termUrl))
    secure3dForm.append(
      CommBankSimplifyHelper.createInput('MD', merchantDetails),
    )

    return secure3dForm
  },

  createSecure3dIframe: (
    token: string,
    currency: string,
    amount: string,
    description: string,
    cardData: ICreatePaymentCard,
  ): Promise<ICreateSecure3dIframeResponse> => {
    return new Promise((resolve) => {
      const secure3dForm = CommBankSimplifyHelper.createSecure3dForm(cardData) // Step 2
      const iframeNode = $('#secure3d-frame')

      $(secure3dForm).insertAfter(iframeNode)
      console.log('create 3d iframe')
      const process3dSecureCallback = function (threadsResponse) {
        console.log('Processing 3D Secure callback...', threadsResponse)
        console.log(
          'simplfiydomain',
          threadsResponse.origin,
          threadsResponse.data,
        )
        window.removeEventListener('message', process3dSecureCallback)

        const simplifyDomains: Array<string> = [
          'https://www.simplify.com',
          'https://simplify.com',
        ] // For UAT you can use 'https://uat.simplify.com'
        const threadResponseData = JSON.parse(threadsResponse.data)
        console.log(
          'aaaa',
          threadsResponse.origin,
          simplifyDomains,
          simplifyDomains.includes(threadsResponse.origin),
          threadResponseData?.secure3d?.authenticated,
        )
        if (
          simplifyDomains.includes(threadsResponse.origin) &&
          threadResponseData?.secure3d?.authenticated
        ) {
          resolve({ token })
        }
      }

      iframeNode.on('load', function () {
        window.addEventListener('message', process3dSecureCallback)
      })

      secure3dForm.submit()
    })
  },
}

export default CommBankSimplifyHelper
