import { IAction } from '../../types'
import {
  CREATE_PAYMENT_TOKEN,
  CREATE_PAYMENT_TOKEN_FAILED,
  CREATE_PAYMENT_TOKEN_SUCCESS,
  PROCESS_PRESCRIPTION_PAYMENT,
  PROCESS_PRESCRIPTION_PAYMENT_FAILED,
  PROCESS_PRESCRIPTION_PAYMENT_SUCCESS,
} from './actions'
import { IPrescriptionFormState } from '../types'

const initialState: IPrescriptionFormState = {
  isProcessing: false,
}

const prescriptionForm = (
  state: IPrescriptionFormState = initialState,
  { type }: IAction,
) => {
  switch (type) {
    case PROCESS_PRESCRIPTION_PAYMENT: {
      return { isProcessing: true }
    }
    case PROCESS_PRESCRIPTION_PAYMENT_FAILED: {
      return { isProcessing: false }
    }
    case PROCESS_PRESCRIPTION_PAYMENT_SUCCESS: {
      return { isProcessing: false }
    }
    case CREATE_PAYMENT_TOKEN: {
      return { isProcessing: true }
    }
    case CREATE_PAYMENT_TOKEN_FAILED: {
      return { isProcessing: false }
    }
    case CREATE_PAYMENT_TOKEN_SUCCESS: {
      return { isProcessing: false }
    }
  }
  return state
}

export { prescriptionForm }
