import * as React from 'react'
import { Provider } from 'react-redux'

import { store } from '../../states/store'

// eslint-disable-next-line react/display-name,react/prop-types
const withReduxProvider = (Component: any) => (
  <Provider store={store}>{Component}</Provider>
)

export { withReduxProvider }
