import axios, { Method } from 'axios'
import { CONFIG } from '../config/Config'

const BASE_API_URL: string = CONFIG.CACLINICS_API_URL

class RequestHelper {
  public static request(
    path: string,
    {
      method = 'GET',
      data,
      params,
    }: {
      method?: Method
      data?: any
      params?: any
    },
  ) {
    return axios({
      url: `${BASE_API_URL}${path}`,
      method,
      data,
      params,
    })
  }
}

export default RequestHelper
