class UtilHelper {
  public static debounce(func: () => void, wait: number, immediate?: boolean) {
    let timeout: any
    return function debouncedFunc(...args: any[]) {
      // @ts-ignore
      const context = this

      function later() {
        timeout = null
        if (!immediate) {
          func.apply(context, args)
        }
      }

      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) {
        func.apply(context, args)
      }
    }
  }

  public static getWindow() {
    return typeof window !== 'undefined' ? window : null
  }
}

export default UtilHelper
