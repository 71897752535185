import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import sagas from './sagas'
import UtilHelper from '../helpers/UtilHelper'
import StateHelper from '../helpers/StateHelper'

import { reducers } from './reducers'

const sagaMiddleware = createSagaMiddleware()
const appliedMiddleware = applyMiddleware(sagaMiddleware)

const createStore = () =>
  reduxCreateStore(
    combineReducers(reducers),
    StateHelper.hydrateState(),
    composeWithDevTools(appliedMiddleware),
  )

const store = createStore()

store.subscribe(
  UtilHelper.debounce(() => {
    StateHelper.persistState([])
  }, 300),
)

sagaMiddleware.run(sagas)

export { store }
