import UtilHelper from './UtilHelper'

class StorageHelper {
  public static getKey(key: string) {
    return UtilHelper.getWindow().btoa(key)
  }

  public static getItem(key: string) {
    const win = UtilHelper.getWindow()
    if (!win) {
      return null
    }
    const storeKey = StorageHelper.getKey(key)
    if (!win.sessionStorage || !win.localStorage) {
      return undefined
    }
    const encodedData =
      win.sessionStorage.getItem(storeKey) || win.localStorage.getItem(storeKey)
    if (!encodedData) {
      return null
    }

    const decodedData = decodeURIComponent(escape(win.atob(encodedData)))
    return JSON.parse(decodedData) || undefined
  }

  public static setItem(key: string, value: string) {
    const win = UtilHelper.getWindow()
    if (!win) {
      return null
    }
    const storeKey = StorageHelper.getKey(key)
    const storeValue = win.btoa(
      unescape(encodeURIComponent(JSON.stringify(value))),
    )
    if (!win.sessionStorage) {
      return null
    }
    win.sessionStorage.setItem(storeKey, storeValue)
    return win.localStorage.setItem(storeKey, storeValue)
  }

  public static removeItem(key: string) {
    const win = UtilHelper.getWindow()
    if (!win) {
      return null
    }
    const storeKey = StorageHelper.getKey(key)
    win.sessionStorage.removeItem(storeKey)
    return win.localStorage.removeItem(storeKey)
  }
}

export default StorageHelper
